import React from "react"
import { Grid, Typography } from "@material-ui/core"
import RecentArticlesListItem from "./RecentArticlesListItem"

const RecentArticlesList = ({ posts }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3">recent articles</Typography>
      </Grid>

      {posts.map((post, index) => (
        <>
          {index >= 1 ? (
            <Grid item xs={3} key={post.frontmatter.title}>
              <RecentArticlesListItem
                key={index * -1}
                title={post.frontmatter.title}
                author={"Bryce Campbell"}
                image={""}
                slug={post.fields.slug}
              />
            </Grid>
          ) : null}
        </>
      ))}
    </Grid>
  )
}

export default RecentArticlesList
