import React from "react";
import { graphql } from "gatsby"
import { Container } from "@material-ui/core";
import Layout from "../components/Layout/layout"
import Blog from '../components/Blog/Blog';
import Squares from '../components/Squares/Squares';
import Hero from "../components/Hero/Hero"
import heroImg from '../assets/images/redesign-rebuild.jpeg'

const BlogPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;
  
  return (
    <Layout>
      <Hero image={heroImg} text={"Webfluent Blog Page"} />
      <Squares>
        <Container>
          {posts.length === 0 
          ? <p>No blog posts found. Add markdown posts to "content/blog" (or the directory you specified for the "gatsby-source-filesystem" plugin in gatsby-config.js).</p>
          : <Blog posts={posts}/> 
          }
        </Container>
      </Squares>
    </Layout>
  )
}


export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
