import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'

const LatestBlog = ({title, summary, image}) => {
    return (
        <Grid container spacing={4} style={{background: '#beddda'}}>
            <Grid item xs={5}>
                <Typography variant='h3' gutterBottom style={{marginBottom: '1rem'}}>latest</Typography>
                <Typography variant='h5' gutterBottom>{title}</Typography>
                <Typography variant='body1' gutterBottom style={{marginBottom: '2rem'}}>{summary}</Typography>
                <Button>read more</Button>
            </Grid>
            <Grid item xs={7} style={{
                padding: 0,
                backgroundImage: `url(${image})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative'
                }}>
            </Grid>
        </Grid>
    )
}

export default LatestBlog
