import { Typography } from '@material-ui/core'
import React from 'react'
import { Link, graphql } from "gatsby"
import img from '../../../../assets/images/redesign-rebuild.jpeg';
const RecentArticlesListItem = ({ title, author, image, slug }) => {
    return (
        <>
            <img src={img} alt={title} style={{ margin: 0 }} />
            <Link to={slug} style={{ textDecoration: 'none'}}>
                <Typography variant='h6' style={{color: 'rgb(37,137,124)'}}>{title}</Typography>
            </Link>
            <Typography variant='body1'>{author}</Typography>
        </>
    )
}

export default RecentArticlesListItem
