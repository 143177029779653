import { Grid } from "@material-ui/core"
import React from "react"
import LatestBlog from "./components/LatestBlog"
import RecentArticles from "./components/RecentArticles/RecentArticlesList"
import img from "../../assets/images/gatsby-vs-nextjs.png"

const Blog = ({ posts }) => {
  console.log(posts)
  return (
    <Grid container>
      {posts.map((post, index) => {
        if (index === 0) {
          return (
            <Grid key={index} item xs={12} style={{ marginBottom: "5rem" }}>
              <LatestBlog
              key={index * -1}
                title={post.frontmatter.title}
                summary={post.frontmatter.description}
                image={img}
              />
            </Grid>
          )
        }
      })}
      <Grid item xs={12}>
        <RecentArticles posts={posts} />
      </Grid>
    </Grid>
  )
}

export default Blog
